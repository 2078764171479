import React, { useState, useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import Axios from "axios"
import MdWrapper from "@/components/MarkdownWrap"


function Index(props) {
    const [markdown, setMarkdown] = useState(null)

    const getMd = () => {
        Axios.get(`${process.env.resourceUrl}docs/anker_event.md`).then(function (data) {
            setMarkdown(data.data)
        })
    }

    useEffect(() => {
        getMd()
    }, [])

    return (
        <Layout menuBlack={true}>
            {markdown != null ? (
                <MdWrapper md={markdown} stl={{ marginTop: 0 }} />
            ) : (
                ""
            )}
          
        </Layout>
    )
}

export default Index